import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./Platform.scss";
import Chart from "./Chart/Chart";
import OpenPosition from "./OpenPosition/OpenPosition";
import { useAuth } from "../../hooks/useAuth";
import Positions from "./Positions/Positions";
import { useGet, GetBalance, StartListeningSocket } from "../../hooks/helper";
import Technical from "./Technical/Technical";
import Summary from "./Summary/Summary";
import { Markets } from "./Markets/Markets";
import Page404 from "../Page/404";
import Calculator from "./Calculator/Calculator";
import { AppContext } from "../../App";
import PageComponents from "../../components/cockpit/PageComponents";


const Platform = () => {
    const { symbol } = useParams();
    const auth = useAuth();
    const get = useGet();
    const [access, setAccess] = useState(false);
    const [leverage, setLeverage] = useState(false);
    const [currentAsset, setCurrentAsset] = useState({ name: '', code: '' });
    const assetsList:any = get.assets;
    const assetsGroups:any = get.assetsGroups;
    const [loading, setLoading] = useState(true);
    const context = useContext(AppContext);

    auth.checkLogin();
    auth.GetData();


    useEffect(() => {
        let isCancelled = false;

        if (symbol && assetsList) {
            const theAsset = assetsList.find((item) => item.symbol === symbol);
            if (!isCancelled && theAsset) {
                setCurrentAsset(theAsset);
                setLeverage(theAsset.position_assets_category.leverage);
                if (
                    theAsset &&
                    auth.info.user.assets_group_permission &&
                    auth.info.user.assets_group_permission.includes(theAsset.asset_category_id) &&
                    auth.info.user.verified_account
                ) {
                    setAccess(true);
                } else {
                    setAccess(false);
                }
            }
            setLoading(false);
        }

        return () => {
            isCancelled = true;
        };
    }, [symbol, assetsList])
    const {summary, equity, free_margin, profit, dataMarket, positions} = GetBalance();

    useEffect(() => {
        // if(auth.info && auth.info.user) StartListeningSocket(auth.info.user.token);
    },[])

    return <>
        <div className="main">
            {currentAsset.name ? <>
                <div className="left">
                    {(currentAsset.name && leverage) && <OpenPosition access={access} leverage={leverage} currentAsset={currentAsset} dataMarket={dataMarket} free_margin={free_margin} />}
                    {(currentAsset.name && leverage) && <Calculator currentAsset={currentAsset} dataMarket={dataMarket} />}
                    {/* {symbol && <Technical symbol={symbol} />} */}
                    {window.innerWidth >= 900 && <Markets dataMarket={dataMarket} assetsGroups={assetsGroups} />}
                </div>
                <div className="right">
                    {auth.info && <Summary summary={summary} equity={equity} free_margin={free_margin} profit={profit} />}
                    <div className="chart">{currentAsset.code && <Chart symbol={currentAsset} />}</div>
                    {auth.info && <Positions dataMarket={dataMarket} positions={positions} />}
                </div>
                {window.innerWidth < 900 && <Markets dataMarket={dataMarket} assetsGroups={assetsGroups} />}
            </> : 
            <>{!loading && <div className="right"><Page404 /></div> }</>
            }
        </div>
        <div className="main" style={{display: 'inline-block', width: '100%', minHeight: 'unset'}}>
            <div>
                {context?.platform && <PageComponents components={context.platform || []} />}
            </div>
        </div>
    </>
}
export default Platform;