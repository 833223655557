import React, { createContext, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import Home from './pages/Home/Home';
import Page from './pages/Page/Page';
import Platform from './pages/Platform/Platform';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { fetchSingleton } from './cockpit/fetch';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Register from './pages/Register/Register';
import { MyAccount } from './pages/MyAccount/MyAccount';
import { Transactions } from './pages/Transactions/Transactions';
import { Contact } from './pages/Contact/Contact';
import { Payments } from './pages/Payment/Payment';
import ScrollIntoView from './hooks/ScrollIntoView';
import AutoLogin from './pages/Login/Auto';
import Affiliate from './pages/Affiliate/Affiliate';
import Referrals from './pages/Referrals/Referrals';
import { useAuth } from './hooks/useAuth';
import * as Config from "./config";
import { useWidgetEvents } from './hooks/helper';

export const AppContext = createContext(null);

export default function App() {
  const [appContext, setAppContext] = useState(null);
  const auth = useAuth();
  useWidgetEvents();

  useEffect(() => {
    let isCancelledSettings = false;

    fetchSingleton('Settings').then(pages => {
      if (!isCancelledSettings) {
        setAppContext(pages);
      }
    });

    return () => {
      isCancelledSettings = true;
    };
  }, [])

  if (!localStorage.getItem('theme-mode')) {
    localStorage.setItem('theme-mode', 'dark');
  }
  useIdleTimer({
    timeout: 1000 * 60 * 20,  //20 minutes without activity = logout
    onIdle: ()=>{
      if(auth.isLoggedIn()) {
        auth.signOut();
      }
    },
    debounce: 500
  })

  return (
    <BrowserRouter>

      <AppContext.Provider value={appContext}>
        <ScrollIntoView>
          <Header />
          <div className="main-container">
            <Switch>

              {!Config.PLATFORM_ONLY && !auth.isLoggedIn() ?
                <Route exact path="/" component={Home} />
                :
                <Route exact path="/" component={Home} >
                  <Redirect to="/platform/BTCUSD" />
                </Route>
              }

              <Route path="/autoLogin/:code" component={AutoLogin} />
              <Route path="/login" component={Login} />
              <Route path="/password/request" component={ForgotPassword} />
              <Route path="/my-account/:page" component={MyAccount} />
              <Route exact path="/my-account">
                <Redirect to="/my-account/info" />
              </Route>
              <Route path="/referrals" component={Referrals} />
              {Config.ALLOW_REGISTRATION ?? <Route path="/register" component={Register} />}
              <Route path="/transactions/:type" component={Transactions} />
              <Route exact path="/transactions"><Redirect to="/transactions/deposits" /></Route>

              <Route path="/platform/:symbol" component={Platform} />
              <Route exact path="/platform" render={(data) => {
                if (!data.location.search) {
                  data.history.push('/platform/BTCUSD')
                } else {
                  const symbol = data.location.search.split('%3A')[1];
                  data.history.push(`/platform/${symbol.replace("USDT", "USD")}`);
                }
              }}></Route>

              <Route path="/payment/:method" component={Payments} />
              <Route exact path="/payment">
                <Redirect to="/payment/creditCard" />
              </Route>

              <Route path="/affiliate" component={Affiliate} />
              <Route path="/contact-us" component={Contact} />
              <Route path="/404" component={() => (<div>404 Not found </div>)} />
              {!Config.PLATFORM_ONLY && <Route path="/:slug" component={Page} />}
            </Switch>
          </div>
          {!Config.PLATFORM_ONLY && <Footer />}
        </ScrollIntoView>
      </AppContext.Provider>

    </BrowserRouter>
  );
}
const AppWithRouter = withRouter(App);

render(
  <BrowserRouter>
    <AppWithRouter />
  </BrowserRouter >
  , document.getElementById('root'),
)