import React from 'react';
import './PositionInfo.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Formating } from '../../../hooks/helper';
import i18n from '../../i18n';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import * as Config from "../../../config";

export const PositionInfo = ({ position, setCurrentPosition, infoModalIsVisible, setInfoModalIsVisible }) => {

    const fields = ['symbolName', 'direction', 'leverage', 'amount', 'open_date', 'open_rate', 'close_date', 'closed_rate', 'take_profit', 'stop_lost', 'profit', 'closingReason'];
    const closingReasons = ['Manually', 'Stop Loss', 'Take Profit', 'Low Margin', 'AI Trade'];

    const closeModal = () => {
        setInfoModalIsVisible(false);
        setCurrentPosition(null);
    }


    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    });

    return (<>
        {infoModalIsVisible &&
            <>
                <div className="positionInfo-bg" onClick={() => closeModal()}></div>
                <div className="positionInfo-modal">
                    <div className="title">
                        <Link to={`/platform/${position.symbol}`} onClick={() => closeModal()}>{position.symbolName} {position.direction} {i18n.t('platform.modal.position')}</Link>
                        <span onClick={() => closeModal()}><FontAwesomeIcon icon={faTimes} /></span>
                    </div>
                    <form>
                        {fields.map((key, i) => {
                            let show = position[key];
                            if (key === 'leverage') { show = `1:${show}` }
                            if (key === 'amount' && show) { show = Formating(show, Config.CURRENCY_SYMBOL) }
                            if (key === 'open_date') { show = new Date(show).toUTCString() }
                            if (key === 'open_rate' && show) { show = Formating(show) }
                            if (key === 'close_date' && show) { show = new Date(show).toUTCString() }
                            if (key === 'closed_rate' && show) { show = Formating(show) }
                            if (key === 'stop_lost' && show) { show = Formating(show) }
                            if (key === 'take_profit' && show) { show = Formating(show) }
                            if (key === 'profit' && show) { show = Formating(show, Config.CURRENCY_SYMBOL) }
                            if (key === 'closingReason') { show = closingReasons[show] }
                            return <Row key={i} className="d-flex">
                                <Col xs={4}>{i18n.t(`platform.modal.${key}`)}</Col>
                                <Col xs={8} className={(key === 'profit') && ((position.profit < 0) ? 'profit minus' : 'profit plus')}>

                                    {(key === 'symbolName')
                                        ?
                                        <Link to={`/platform/${position.symbol}`} onClick={() => closeModal()}>{show}</Link>
                                        :
                                        (key === 'closingReason' && position.profit === null) ? '' : show
                                    }

                                </Col>
                            </Row>
                        })}
                    </form>
                </div>
            </>
        }
    </>);
}